<template>
  <div class="slot-card" @click="selectedValue = bookSlot.unavailable[0] ? false : !selectedValue" :class="{ unavailable: bookSlot.unavailable[0] }">
    <div class="info">
      <div class="time">{{ formatTime(bookSlot.start) }}-{{ formatTime(bookSlot.end) }}</div>
      <div class="count">{{ bookSlot.unavailable[0] ? bookSlot.unavailable[1] : '1 занятие' }}</div>
    </div>
    <div class="actions">
      <CoinIndicator :coins="priceWithDiscount" class="coins" :has-discount="bookSlot.hasDiscount || (discount && !discountUsed)" />
      <Checkbox v-model="selectedValue" not-clickable />
    </div>
  </div>
</template>

<script setup>
import CoinIndicator from '@/components/parts/CoinIndicator'
import Checkbox from '@/components/common/Checkbox'
import { formatTime } from '~/utils/date'

const props = defineProps({
  bookSlot: {
    type: Object,
    required: true
  },
  price: {
    type: Number,
    required: true
  },
  discount: {
    type: Number
  },
  selected: Boolean,
  discountUsed: Boolean
})
const emit = defineEmits(['update:selected'])

const selectedValue = computed({
  get: () => props.selected,
  set: value => emit('update:selected', value)
})

const priceWithDiscount = computed(() => {
  if (props.discount && (props.bookSlot.hasDiscount || !props.discountUsed)) {
    return Math.ceil(props.price * (100 - props.discount) / 100)
  }

  return props.price
})
</script>

<style scoped lang="scss">
.slot-card {
  border: 1px solid var(--color-separator);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: .1s border-color;
  &:hover {
    border-color: var(--color-primary);
  }
  & .info {
    & .time {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      @media (max-width: 450px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    & .count {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--color-elements-secondary);
      @media (max-width: 450px) {
        font-size: 11px;
        line-height: 18px;
      }
    }
  }
  & .actions {
    display: flex;
    align-items: center;
    & .coins {
      margin-right: 80px;
      @media (max-width: 450px) {
        margin-right: 20px;
      }
    }
  }
  &.unavailable {
    opacity: .5;
    pointer-events: none;
  }
}
</style>
