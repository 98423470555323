<template>
  <main>
    <div class="horiz-container">
      <div class="top">
        <h1 class="page-title">Бронирование занятий</h1>
      </div>
      <div class="content default-grid">
        <div class="calendar-container">
          <Calendar :selected-day="selectedDay" @select-day="selectDay" :disabled="!!user.is_mentor" />
        </div>
        <div class="mentor-container">
          <div class="title">Ментор</div>
          <MentoringInfo v-if="mentor" v-bind="mentor" :sub="skills" desc view-btn />
        </div>
      </div>
    </div>
    <Teleport to="body">
      <transition name="sidebar-fluid" mode="out-in">
        <MentorBookSidebar
          v-if="selectedDay !== null"
          @close="selectedDay = null"
          :day="selectedDay"
          :key="selectedDay?.dateString"
          :mentor="mentor"
        />
      </transition>
    </Teleport>
  </main>
</template>

<script setup>
import Calendar from '@/components/calendar/Calendar'
import useDaySelection from '~/composables/calendar/daySelection'
import MentorBookSidebar from '@/components/mentor/MentorBookSidebar'
import MentoringInfo from '@/components/mentor/MentoringInfo'
import { getProfile } from '~/api/user'

useHead({
  title: 'Бронирование занятий'
})

const route = useRoute()
const user = inject('user')

const { selectedDay, selectDay } = useDaySelection()

const { data: mentor } = await useAsyncData(() => getProfile(route.params.id))
if (!mentor.value) throw createNotFoundError()

const skills = computed(() => mentor.value.mentor_skills.slice(0, 2).map(s => s.skill_name ?? s.name).join(', '))
</script>

<style scoped lang="scss">
.top {
  border-bottom: 1px solid var(--color-separator);
  & .page-title {
    margin-bottom: 55px;
  }
}

.content {
  padding-top: 50px;

  & .mentor-container {
    grid-column: span 3;
    @media (max-width: 1280px) {
      grid-column: span 12;
      grid-row: 1;
      margin-bottom: 40px;
    }
    & .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      text-transform: uppercase;
      margin-bottom: 45px;
    }
  }

  & .calendar-container {
    grid-column: span 9;
    @media (max-width: 1280px) {
      grid-column: span 12;
    }
  }
}
</style>
